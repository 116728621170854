import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormCheckbox,
  FormDate,
  FormProvider,
  MultiSelectTreeControl,
  SelectControl,
  Option,
} from '@liveconnect/components'

import { FiltersModal } from '@liveconnect/communities-ui'

import { PublishersResponse } from '../../../core/posts/types'

import { buildValidationSchema } from './validations'

import './styles.scss'

interface WallFiltersProps {
  isActive: boolean
  onClose: () => void
  onChange: (values: WallFiltersParams) => void
  publishers: PublishersResponse[]
  selectedPublisher: string
  selectedChannels: string[]
  isScheduled?: boolean
}

export interface WallFiltersParams {
  publisher_external_id: string
  channels_ids: string[]
  showsponsored: boolean
  fromdate: string
  todate: string
}

export const WallFilters: FC<WallFiltersProps> = ({
  isActive,
  onClose,
  onChange,
  publishers,
  selectedPublisher,
  selectedChannels,
  isScheduled = false,
}) => {
  const { t } = useTranslation()
  const [channelsOptions, setChannelsOptions] = useState<Option<string>[]>([])
  const [selectedPublisherId, setSelectedPublisherId] =
    useState<string>(selectedPublisher)
  const [selectedChannelsIds, setSelectedChannelsIds] =
    useState<string[]>(selectedChannels)

  const methods = useForm<WallFiltersParams>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      todate: '',
      fromdate: '',
      showsponsored: false,
    },
  })

  const { control, handleSubmit, reset, setValue, getValues } = methods

  const onReset = () => {
    setSelectedPublisherId(selectedPublisher)
    setSelectedChannelsIds(selectedChannels)
    reset()
  }

  const onSubmit = () => {
    setValue('publisher_external_id', selectedPublisherId)
    setValue('channels_ids', selectedChannelsIds)
    onChange(getValues())
    onClose()
  }

  const publishersOptions = useMemo(() => {
    const options = publishers?.map((publisher: PublishersResponse) => ({
      value: publisher.id,
      label: publisher.name,
    }))

    if (isScheduled)
      options.unshift({ value: '', label: t('post.select.allPublishers') })

    return options
  }, [publishers])

  const parseChannels = () => {
    const _publisher = publishers.find(
      (publish) => publish.id === selectedPublisherId
    )
    const parsChannels = _publisher?.channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    if (parsChannels) {
      setChannelsOptions(parsChannels)
      setSelectedChannelsIds(
        _publisher?.channels.map((channel) => channel.id) ?? []
      )
    }
  }

  useEffect(() => {
    !selectedPublisherId &&
      selectedChannels.length > 0 &&
      setSelectedChannelsIds([])
    parseChannels()
  }, [selectedPublisherId])

  useEffect(() => {
    setSelectedPublisherId(selectedPublisher)
    setSelectedChannelsIds(selectedChannels)
  }, [selectedPublisher, selectedChannels])

  const placeholderChannels = useMemo(() => {
    if (selectedChannelsIds.length === channelsOptions.length) {
      return t('post.header.select.channels.all')
    }
    if (selectedChannelsIds.length > 0) {
      return t('post.header.select.channels', {
        count: selectedChannelsIds.length,
      })
    }
    return t('post.header.select.channels.empty')
  }, [selectedChannelsIds, channelsOptions])

  return (
    <FormProvider methods={methods}>
      <FiltersModal
        isOpened={isActive}
        onClose={onClose}
        onReset={onReset}
        onFilter={handleSubmit(onSubmit)}
        texts={{
          title: t('filtersModal.filters'),
          reset: t('filtersModal.clear'),
          viewResults: t('filtersModal.viewResults'),
        }}
      >
        <FiltersModal.Section title={t('post.filters.publisher')}>
          <SelectControl
            name="publisher_external_id"
            label={t('post.header.select.publisher')}
            placeholder={t('post.header.select.publisher')}
            options={publishersOptions}
            value={selectedPublisherId}
            required={true}
            noOptionsMessage={t('select.empty')}
            isClearable={false}
            onChange={setSelectedPublisherId}
          />
          <MultiSelectTreeControl
            name="channels_ids"
            value={selectedChannelsIds}
            label={t('post.header.select.channels.label')}
            options={channelsOptions}
            placeholder={
              selectedPublisherId === ''
                ? t('post.header.select.channels.all')
                : t('post.header.select.channels.empty')
            }
            placeholderInfo={() => placeholderChannels}
            required={true}
            disabled={selectedPublisherId === ''}
            onChange={setSelectedChannelsIds}
          />
          <FormCheckbox
            control={control}
            name="showsponsored"
            label={t('post.filters.sponsored')}
          />
        </FiltersModal.Section>

        {!isScheduled && (
          <FiltersModal.Section title={t('post.filters.date')}>
            <div className="row">
              <div className="col-6">
                <FormDate
                  label={t('post.filters.date.start')}
                  name="fromdate"
                  control={control}
                />
              </div>
              <div className="col-6">
                <FormDate
                  label={t('post.filters.date.end')}
                  name="todate"
                  control={control}
                />
              </div>
            </div>
          </FiltersModal.Section>
        )}
      </FiltersModal>
    </FormProvider>
  )
}
