import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import timezones from 'timezones-list'
import { zonedTimeToUtc } from 'date-fns-tz'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  FormCheckbox,
  FormRichText,
  FormSelect,
  FormTextInput,
  FormToggle,
  Loader,
  FormProvider,
  Tooltip,
  FormDateTime,
  MultiSelectTreeControl,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import InfoBlock from '../../../components/InfoBlock'
import { Main } from '../../../components/Main'
import {
  FormPostCreateFields,
  PostCreateParamsType,
  PostStateEnum,
  PublishersResponse,
  PublisherTypeEnum,
  SelectType,
} from '../../../core/posts/types'
import usePosts from '../../../core/posts/usePosts'
import { buildValidationSchema } from './validation'
import useNotifications from '../../../utils/notifications/useNotifications'
import { characterCounterText } from '../../../utils/characterCounterText'
import FormActions from '../../../components/forms/FormActions'
import { useCustomRouter } from '../../../utils/extractParams'
import ChannelsArrayItem from './ChannelsArrayItem'
import { SimpleUploadControl } from '../../../components/forms/SimpleUploadControl'
import VideoPlayer from '../../../components/VideoPlayer'
import VideoUpload from './VideoUpload'
import { FetchError } from '../../../utils/fetch/types'
import useUi from '../../../core/ui/useUi'
import usePermissions from '../../../core/permissions/usePermissions'

import './styles.scss'

interface dataState {
  selectedPublisher: PublishersResponse
}

const PostCreate: FC = () => {
  const { t } = useTranslation()
  const { getPublishers, uploadPostImage, createPost } = usePosts()
  const navigate = useNavigate()
  const { verifyModeratorWall } = usePermissions()
  const { showConfirmation } = useUi()
  const { basePath } = useCustomRouter()
  const notify = useNotifications()
  const { state } = useLocation()
  const [publishersData, setPublishersData] = useState<PublishersResponse[]>([])
  const [valuesPublishers, setValuesPublishers] = useState<SelectType[]>([])
  const [valuesChannels, setValuesChannels] = useState<SelectType[]>([])
  const [valuesChannelsSponsor, setValuesChannelsSponsor] = useState<
    SelectType[]
  >([])
  const [isIfema, setIsIfema] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false)

  const [showImageSponsorUploadModal, setShowImageSponsorUploadModal] =
    useState<boolean>(false)

  const [showVideoUploadModal, setShowVideoUploadModal] =
    useState<boolean>(false)
  const [imagePath, setimagePath] = useState<string>('')
  const [imagePathSponsor, setimagePathSponsor] = useState<string>('')
  const [videoURL, setVideoURL] = useState<string>('')
  const [isVideoValid, setIsVideoValid] = useState<boolean>(true)
  const [timezoneList, setTimezoneList] = useState<
    { label: string; value: string }[]
  >([])

  const feedbackTexts = {
    [PostStateEnum.PUBLISHED]: [
      t('post.create.succeessFeedback'),
      t('post.create.unknownError'),
    ],
    [PostStateEnum.DRAFTED]: [
      t('post.create.succeessFeedback.drafted'),
      t('post.create.unknownError.drafted'),
      t('post.create.succeessFeedback.drafted.sponsored'),
      t('post.create.unknownError.drafted.sponsored'),
    ],
    [PostStateEnum.SCHEDULED]: [
      t('post.create.succeessFeedback.scheduled'),
      t('post.create.unknownError.scheduled'),
      t('post.create.succeessFeedback.scheduled.sponsored'),
      t('post.create.unknownError.scheduled.sponsored'),
    ],
  }

  const methods = useForm<FormPostCreateFields>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      channelsArray: [{ id: '', themes: [] }],
      channelsIds: [],
    },
  })
  const {
    control,
    reset,
    watch,
    setError,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid },
  } = methods

  const { fields, append, remove } = useFieldArray<FormPostCreateFields>({
    control,
    name: 'channelsArray',
  })

  const watchChannelList = watch('channelsArray')
  const watchPublisher = watch('publisher')
  const watchChannelsIds = watch('channelsIds')
  const watchSponsoredChannelsIds = watch('sponsoredChannels')
  const watchOption = watch('option')
  const watchSponsored = watch('isSponsored')

  const placeholderChannels = useMemo(() => {
    const watchLength = watchChannelsIds ? watchChannelsIds.length : 0
    if (watchLength === valuesChannels.length) {
      return t('post.header.select.channels.all')
    }
    if (watchLength > 0) {
      return t('post.header.select.channels', {
        count: watchLength,
      })
    }
    return t('post.header.select.channels.empty')
  }, [watchChannelsIds, valuesChannels])

  const placeholderSponsoredChannels = useMemo(() => {
    const watchLength = watchSponsoredChannelsIds
      ? watchSponsoredChannelsIds.length
      : 0
    if (watchLength === valuesChannelsSponsor.length) {
      return t('post.header.select.channels.all')
    }
    if (watchLength > 0) {
      return t('post.header.select.channels', {
        count: watchLength,
      })
    }
    return t('post.header.select.channels.empty')
  }, [watchSponsoredChannelsIds, valuesChannelsSponsor])

  const fetchTZ = async () => {
    const tzs = (await timezones).map((item) => {
      return {
        label: item.label,
        value: item.tzCode,
      }
    })
    tzs && setTimezoneList(tzs)
  }

  const handleSetImage = (image: string) => {
    setimagePath(image)
    setShowImageUploadModal(false)
    handleRemoveVideo()
  }

  const handleSetImageSponsor = (image: string) => {
    setimagePathSponsor(image)
    setShowImageSponsorUploadModal(false)
  }

  const handleRemoveImageSponsor = () => {
    setimagePathSponsor('')
    setValue('sponsoredImagePath', '')
  }

  const handleRemoveImage = () => {
    setimagePath('')
    setValue('imagePath', '')
  }

  const handleRemoveVideo = () => {
    setVideoURL('')
    setValue('video', '')
    setIsVideoValid(true)
  }

  const handleSetVideo = (videoUrl: string) => {
    setValue('video', videoUrl)
    setVideoURL(videoUrl)
    setShowVideoUploadModal(false)
    handleRemoveImage()
  }

  const loadingPublisher = () => {
    if (state && (state as dataState)) {
      const _publisher: PublishersResponse = (state as dataState)
        .selectedPublisher
      if (_publisher.type === PublisherTypeEnum.TENANT) {
        const parsChannels = _publisher?.channels.map((channel) => ({
          value: channel.id,
          label: channel.name ? channel.name : '',
        }))
        parsChannels && setValuesChannels(parsChannels)
        const transformChannels = _publisher?.channels.map(
          (channel) => channel.id
        )
        setimagePath('')
        setVideoURL('')
        return reset({
          publisher: _publisher.id,
          channelsIds: transformChannels,
          isHighlighted: false,
          channelsArray: [{ id: '', themes: [] }],
          title: '',
          message: '',
          imagePath: undefined,
          video: undefined,
          option: false,
          timezone: undefined,
          date: undefined,
        })
      }
      setimagePath('')
      setVideoURL('')
      return reset({
        publisher: _publisher.id,
        channelsIds: [],
        isHighlighted: false,
        channelsArray: [{ id: '', themes: [] }],
        title: '',
        message: '',
        imagePath: undefined,
        video: undefined,
        option: false,
        timezone: undefined,
        date: undefined,
      })
    }
  }

  const parsePublishers = (publis: PublishersResponse[]) => {
    return publis?.map((publisher: PublishersResponse) => ({
      value: publisher.id,
      label: publisher.name,
    }))
  }

  const fetchPublishers = async () => {
    try {
      const _publishers = await getPublishers()
      _publishers && setPublishersData(_publishers)
      if (_publishers) {
        const transformValues = parsePublishers(_publishers)
        transformValues && setValuesPublishers(transformValues)
        await loadingPublisher()
      }
    } catch (error) {
      notify.error(t('common.unknownError'))
    }
  }

  const numberChannels = () => {
    const _publisher = publishersData.find(
      (publish) => publish.id === watchPublisher
    )
    const parsChannels = _publisher?.channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    if (parsChannels) {
      return parsChannels.length
    }
    return undefined
  }

  const getChannelsOptions = (id: number) => {
    const watchChannel = getValues(`channelsArray.${id}.id`)
    const _selectedChannels = (watchChannelList || [])
      .filter((item) => item.id !== watchChannel)
      .map((item) => item.id)
    const _publisher = publishersData.find(
      (publish) => publish.id === watchPublisher
    )
    const parsChannels = _publisher?.channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    if (parsChannels) {
      const valuesReturn = parsChannels.filter(
        (item) => !_selectedChannels.includes(item.value)
      )
      return valuesReturn
    }
    return []
  }

  const getChannelsSponsored = (idPublisher: string) => {
    const currentPublisher = publishersData.filter(
      (publisher) => publisher.id === idPublisher
    )
    const parsChannels = currentPublisher[0].channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    setValuesChannelsSponsor(parsChannels)
  }

  const handleError = () => {
    return showConfirmation({
      title: t('common.warning'),
      subtitle: t('post.create.sponsored.error'),
      text: t('post.create.sponsored.error.text'),
      confirmText: t('common.accept'),
      iconName: 'report_problem',
      onConfirm: () => {
        setError('sponsoredDateUnpin', {
          type: 'custom',
          message: t(`post.create.sponsored.error`),
        })
        setError('sponsoredDateHide', {
          type: 'custom',
          message: t(`post.create.sponsored.error`),
        })
      },
    })
  }

  const onSubmit = async (
    values: FormPostCreateFields,
    stateParam: PostStateEnum
  ) => {
    setIsSubmitting(true)
    const dataSend: PostCreateParamsType = {
      title: values.title,
      message: values.message,
      image: values.imagePath || '',
      video: values.video || '',
      publisherType: isIfema
        ? PublisherTypeEnum.TENANT
        : PublisherTypeEnum.BRAND,
      publisherId: values.publisher,
      isHighlighted: values.isHighlighted,
      state: stateParam,
      publishDateTime: null,
      timezone: values.timezone || '',
      channelsIds: isIfema ? values.channelsIds : [],
      themesIds: [],
      isSponsored: !isIfema ? values.isSponsored : false,
      sponsorName:
        !isIfema && values.isSponsored ? values.sponsoredTitle : null,
      sponsorURL: !isIfema && values.isSponsored ? values.sponsoredUrl : null,
      sponsorSlogan:
        !isIfema && values.isSponsored ? values.sponsoredSlogan : null,
      sponsorImage: null,
    }
    if (!isIfema) {
      /* ADD channelsIds */
      if (!values.isSponsored) {
        for (const channel of values.channelsArray || []) {
          dataSend.channelsIds.push(channel.id)
          for (const theme of channel.themes) {
            if (!dataSend.themesIds.includes(theme))
              dataSend.themesIds.push(theme)
          }
        }
      } else {
        dataSend.channelsIds = values.sponsoredChannels
      }
      /* END ADD channelsIds */
    }
    if (stateParam === PostStateEnum.SCHEDULED) {
      if (values.date && values.timezone) {
        const newDate = new Date(values.date)
        const utcDate = zonedTimeToUtc(newDate, values.timezone)
        dataSend.publishDateTime = utcDate.toISOString()
      }
      /* DATE SPONSORED */
      if (
        values.isSponsored &&
        values.sponsoredDateUnpin &&
        values.sponsoredDateHide &&
        values.timezone
      ) {
        const utcDateUnpinned = zonedTimeToUtc(
          new Date(values.sponsoredDateUnpin),
          values.timezone
        )
        dataSend.unpinnedDate = utcDateUnpinned.toISOString()
        const utcDateHide = zonedTimeToUtc(
          new Date(values.sponsoredDateHide),
          values.timezone
        )
        dataSend.hidingDate = utcDateHide.toISOString()
      }
      /* END DATE SPONSORED */
    }
    const [
      successMessage,
      errorMessage,
      successMessageSponsored,
      errorMessageSponsored,
    ] = feedbackTexts[stateParam]
    try {
      if (imagePath && imagePath.startsWith('blob')) {
        const image = await uploadPostImage(imagePath)
        if (image) {
          dataSend.image = image.uri
        }
      }
      /* UPLOAD IMAGE SPONSORED */
      if (
        values.isSponsored &&
        imagePathSponsor &&
        imagePathSponsor.startsWith('blob')
      ) {
        const image = await uploadPostImage(imagePathSponsor)
        if (image) {
          dataSend.sponsorImage = image.uri
        }
      }
      /*END UPLOAD IMAGE SPONSORED */
      await createPost(dataSend)
      notify.success(
        values.isSponsored ? successMessageSponsored : successMessage
      )
      navigate(`${basePath}/wall`)
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        handleError()
      } else {
        notify.error(values.isSponsored ? errorMessageSponsored : errorMessage)
      }
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    const ifemaPublish = publishersData.find(
      (publish) => publish.type === PublisherTypeEnum.TENANT
    )
    if (ifemaPublish?.id === watchPublisher) {
      const parsChannels = ifemaPublish?.channels.map((channel) => ({
        value: channel.id,
        label: channel.name ? channel.name : '',
      }))
      parsChannels && setValuesChannels(parsChannels)
      const transformChannels = ifemaPublish?.channels.map(
        (channel) => channel.id
      )
      reset({
        publisher: watchPublisher,
        channelsIds: transformChannels,
        isHighlighted: false,
        channelsArray: [{ id: '', themes: [] }],
        title: '',
        message: '',
        imagePath: undefined,
        video: undefined,
        option: false,
        timezone: undefined,
        date: undefined,
      })
      setimagePath('')
      setVideoURL('')
      setIsIfema(true)
    } else {
      fields.map((field, index) => {
        getChannelsOptions(index)
      })
      getChannelsSponsored(watchPublisher)
      reset({
        publisher: watchPublisher,
        channelsIds: [],
        isHighlighted: false,
        channelsArray: [{ id: '', themes: [] }],
        title: '',
        message: '',
        imagePath: undefined,
        video: undefined,
        option: false,
        timezone: undefined,
        date: undefined,
      })
      setimagePath('')
      setVideoURL('')
      setIsIfema(false)
    }
  }, [watchPublisher])

  useEffect(() => {
    if (watchSponsored) {
      setValue('option', true)
    }
  }, [watchSponsored])

  useEffect(() => {
    fetchPublishers()
  }, [])

  useEffect(() => {
    const shouldBlurActiveElement =
      !showVideoUploadModal ||
      !showImageUploadModal ||
      !showImageSponsorUploadModal
    if (shouldBlurActiveElement) (document.activeElement as HTMLElement).blur()
  }, [showVideoUploadModal, showImageUploadModal, showImageSponsorUploadModal])

  useEffect(() => {
    fetchTZ()
  }, [])
  useEffect(() => {
    if (!verifyModeratorWall()) {
      navigate(`${basePath}/access-denied`)
    }
  }, [])

  return (
    <Main ariaLabelledby="postCreate" className="PostCreate">
      <h1 id="post-create-title" className="h3" data-testid="PostCreate__title">
        {t('post.modal.title')}
      </h1>
      <div className="row">
        <div className="col">
          <InfoBlock
            className="mb-0"
            label={t('post.create.info')}
            data-testid="PostCreate__infoBlock"
          />
        </div>
      </div>
      <div className="PostCreate__form">
        <FormProvider methods={methods}>
          <div className="row mbInput">
            <div className="col-7">
              <FormSelect
                control={control}
                name="publisher"
                label={t('post.header.select.publisher')}
                placeholder={t('post.header.select.publisher')}
                options={valuesPublishers}
                required
                noOptionsMessage={t('select.empty')}
                data-testid="PostCreate__publisher"
                isClearable={false}
              />
            </div>
          </div>
          {isIfema ? (
            <div className="row mbInput">
              <div className="col-7">
                <Controller
                  control={control}
                  name="channelsIds"
                  render={({ field, fieldState }) => (
                    <MultiSelectTreeControl
                      error={fieldState.error}
                      name={field.name}
                      value={field.value ?? []}
                      label={t('post.create.channel.label')}
                      options={valuesChannels}
                      placeholder={t('post.create.channel.label')}
                      placeholderInfo={() => placeholderChannels}
                      required={true}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      data-testid="PostCreate__channelsIfema"
                    />
                  )}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row mbInput">
                <div className="PostCreate__form__toggle col-3">
                  <FormToggle
                    label={t('post.create.step3.title')}
                    control={control}
                    name="isHighlighted"
                    data-testid="PostCreate__highlighted"
                  />
                </div>
                <div className="PostCreate__form__toggle col-3">
                  <FormToggle
                    label={t('post.create.sponsored')}
                    control={control}
                    name="isSponsored"
                    data-testid="PostCreate__isSponsored"
                  />
                </div>
              </div>
              {!watchSponsored ? (
                <div className="mbInput">
                  {fields.map((field, index) => (
                    <div className="row" key={index}>
                      <div className="col-11">
                        <ChannelsArrayItem
                          key={fields.length}
                          field={field}
                          fieldIndex={index}
                          control={control}
                          setValue={setValue}
                          watch={watch}
                          channelsAvailable={getChannelsOptions(index)}
                          data-testid="PostCreate__channelsSponsored"
                        />
                      </div>
                      <div className="col-1">
                        {fields.length > 1 && (
                          <button
                            className="non-styled-btn deleteButton"
                            onClick={() => remove(index)}
                            data-testid={`PostCreate__deleteChannel${index}`}
                          >
                            <Icon name="delete_outline" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <button
                    className="moreButton"
                    onClick={() => append({ id: '', themes: [] })}
                    disabled={fields.length === numberChannels()}
                  >
                    {t('post.add.channel')}
                    <Icon name={'add'} />
                  </button>
                </div>
              ) : (
                <div className="sponsoredSection">
                  <p className="sponsoredSection__title">
                    {t('post.create.sponsored.title')}
                  </p>
                  <div className="row">
                    <div className="col-4 mbInput">
                      <FormTextInput
                        control={control}
                        name="sponsoredTitle"
                        label={t('post.create.sponsored.name')}
                        placeholder={t(
                          'post.create.sponsored.name.placeholder'
                        )}
                        type="text"
                        required={true}
                        data-testid="PostCreate__sponsoredTitle"
                      />
                    </div>
                    <div className="col-4 mbInput">
                      <FormTextInput
                        control={control}
                        name="sponsoredUrl"
                        label={t('post.create.sponsored.url')}
                        placeholder={t('post.create.sponsored.url.placeholder')}
                        type="text"
                        required={true}
                        data-testid="PostCreate__sponsoredUrl"
                      />
                    </div>
                    <div className="col-8 mbInput sponsoredSection__slogan">
                      <FormTextInput
                        control={control}
                        name="sponsoredSlogan"
                        label={t('post.create.sponsored.slogan')}
                        placeholder={t(
                          'post.create.sponsored.slogan.placeholder'
                        )}
                        type="text"
                        required={true}
                        data-testid="PostCreate__sponsoredSlogan"
                      />
                      <span className="max">
                        {t('post.create.max', {
                          numberCharac: 60,
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="sponsoredSection__titleLogo">
                        {t('post.create.sponsored.logo')} <span>*</span>
                      </p>
                    </div>
                    <div className="col-12 mbInput">
                      <div className="uploader-container">
                        <div className="uploader-container__components">
                          <div className="imageUploader">
                            <SimpleUploadControl
                              control={control}
                              title={t('post.modal.image')}
                              label={t('common.addImage')}
                              name="sponsoredImagePath"
                              height={300}
                              width={300}
                              value={imagePathSponsor}
                              onChange={handleSetImageSponsor}
                              onRemoveFile={handleRemoveImageSponsor}
                              isOpen={showImageSponsorUploadModal}
                              handleOpen={() =>
                                setShowImageSponsorUploadModal(false)
                              }
                            />
                          </div>
                        </div>
                        <div className="uploader-container__buttons">
                          <span
                            className="fw-bold d-none d-lg-inline"
                            tabIndex={-1}
                          >
                            <Tooltip
                              content={t('post.imageUpload.title')}
                              className={
                                imagePathSponsor
                                  ? 'toolActions withVideo'
                                  : 'toolActions'
                              }
                              position="right"
                            >
                              <Icon
                                name="image"
                                onClick={() =>
                                  setShowImageSponsorUploadModal(true)
                                }
                                tabIndex={0}
                                data-testid="PostCreate__sponsoredUploader"
                              />
                            </Tooltip>
                          </span>
                          <span className="fw-bold d-inline d-lg-none">
                            <Icon
                              name="image"
                              onClick={() =>
                                setShowImageSponsorUploadModal(true)
                              }
                            />
                            <Icon
                              name="image"
                              onClick={() =>
                                setShowImageSponsorUploadModal(true)
                              }
                              tabIndex={0}
                              data-testid="PostCreate__sponsoredUploader_mobile"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mbInput">
                      <Controller
                        control={control}
                        name="sponsoredChannels"
                        render={({ field, fieldState }) => (
                          <MultiSelectTreeControl
                            error={fieldState.error}
                            name={field.name}
                            value={field.value ?? []}
                            label={t('post.create.channel.label')}
                            options={valuesChannelsSponsor}
                            placeholder={t('post.create.channel.label')}
                            placeholderInfo={() => placeholderSponsoredChannels}
                            required={true}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            data-testid="PostCreate__sponsoredChannels"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="row mbInput">
            <div className="col-9">
              <FormTextInput
                control={control}
                name="title"
                label={t('post.create.title.label')}
                placeholder={t('post.create.title.placeholder')}
                type="text"
                required={true}
                data-testid="PostCreate__titleForm"
                helperText={t('validations.leyendField.max', {
                  number: 200,
                })}
              />
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12">
              <FormRichText
                label={t('post.create.message.label')}
                control={control}
                name="message"
                placeholder={t('post.modal.text')}
                required={true}
                data-testid="PostCreate__message"
                helperText={characterCounterText(
                  watch('message') ?? '',
                  5000,
                  t
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InfoBlock
                label={t('post.create.remember')}
                data-testid="PostCreate__infoRemember"
              />
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12">
              <div className="uploader-container">
                <div className="uploader-container__components">
                  <div className="imageUploader">
                    <SimpleUploadControl
                      control={control}
                      title={t('post.modal.image')}
                      label={t('common.addImage')}
                      name="imagePath"
                      value={imagePath}
                      onChange={handleSetImage}
                      onRemoveFile={handleRemoveImage}
                      isOpen={showImageUploadModal}
                      handleOpen={() => setShowImageUploadModal(false)}
                    />
                  </div>
                  <div className="videoUploader">
                    {videoURL && (
                      <>
                        <div className="videoUploader__player">
                          <VideoPlayer
                            url={videoURL}
                            onError={() => setIsVideoValid(false)}
                            onSuccess={() => setIsVideoValid(true)}
                          />
                          {!isVideoValid && (
                            <div className="videoUploader__error">
                              <Icon name="report_problem" />
                              {t('post.videoUpload.error.message')}
                            </div>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div
                          className="videoUploader__delete"
                          onClick={handleRemoveVideo}
                        >
                          <div className="videoUploader__delete__inner">
                            <Icon
                              name="delete_outline"
                              data-testid="PostCreate__deleteVideo"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="uploader-container__buttons">
                  <span className="fw-bold d-none d-lg-inline" tabIndex={-1}>
                    <Tooltip
                      content={t('post.imageUpload.title')}
                      className={
                        imagePath || videoURL
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="image"
                        onClick={() => setShowImageUploadModal(true)}
                        tabIndex={0}
                        data-testid="PostCreate__imageUpload"
                      />
                    </Tooltip>
                    <Tooltip
                      content={t('post.videoUpload.title')}
                      className={
                        videoURL || imagePath
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="video_link"
                        className="CreatePost__video-icon"
                        onClick={() => setShowVideoUploadModal(true)}
                        tabIndex={0}
                        data-testid="PostCreate__videoUpload"
                      />
                    </Tooltip>
                  </span>
                  <span className="fw-bold d-inline d-lg-none">
                    <Icon
                      name="image"
                      onClick={() => setShowImageUploadModal(true)}
                      data-testid="PostCreate__imageUpload_mobile"
                    />
                    <Icon
                      name="video_link"
                      className="CreatePost__video-icon"
                      onClick={() => setShowVideoUploadModal(true)}
                      data-testid="PostCreate__videoUpload_mobile"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mbInput">
            <div className="col-12 contentCheck">
              <FormCheckbox
                control={control}
                name="option"
                label={t('post.program.timezone')}
                data-testid="PostCreate__option"
              />
            </div>
          </div>
          {watchOption && (
            <>
              <div className="row mbInput">
                <div className="col-4">
                  <FormSelect
                    control={control}
                    name="timezone"
                    label={t('post.timezone')}
                    placeholder={t('post.select.timezone')}
                    options={timezoneList}
                    required={true}
                    noOptionsMessage={t('select.empty')}
                    isClearable={false}
                    data-testid="PostCreate__timezone"
                  />
                </div>
                <div className="col-4">
                  <FormDateTime
                    control={control}
                    name="date"
                    label={t('post.date.timezone')}
                    required
                    data-testid="PostCreate__date"
                  />
                </div>
              </div>
              {watchSponsored && (
                <div className="row mbInput">
                  <div className="col-4">
                    <FormDateTime
                      control={control}
                      name="sponsoredDateUnpin"
                      label={t('post.create.sponsored.date.unpin')}
                      tooltip={t('post.create.sponsored.date.unpin.tooltip')}
                      required
                      data-testid="PostCreate__sponsoredDateUnpin"
                    />
                  </div>
                  <div className="col-4">
                    <FormDateTime
                      control={control}
                      name="sponsoredDateHide"
                      label={t('post.create.sponsored.date.hide')}
                      tooltip={t('post.create.sponsored.date.hide.tooltip')}
                      required
                      data-testid="PostCreate__sponsoredDateHide"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <FormActions
            languageSelector={
              <button
                className="rightButton"
                onClick={handleSubmit((data) =>
                  onSubmit(data, PostStateEnum.DRAFTED)
                )}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  watchOption ||
                  (watchSponsored && !imagePathSponsor)
                }
                data-testid="PostCreate__drafted"
              >
                <p>{t('post.save.drafted')}</p>
                <Icon name="chevron_right" />
              </button>
            }
          >
            <button
              className="btn btn-outline-primary"
              onClick={() => navigate(`${basePath}/wall`)}
            >
              {t('common.cancel')}
            </button>
            {!watchOption ? (
              <button
                className="btn btn-primary"
                onClick={handleSubmit((data) =>
                  onSubmit(data, PostStateEnum.PUBLISHED)
                )}
                disabled={
                  !isValid || isSubmitting || !isVideoValid || watchSponsored
                }
                data-testid="PostCreate__published"
              >
                {isSubmitting ? <Loader /> : t('common.publish')}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={handleSubmit((data) =>
                  onSubmit(data, PostStateEnum.SCHEDULED)
                )}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !isVideoValid ||
                  (watchSponsored && !imagePathSponsor)
                }
                data-testid="PostCreate__scheduled"
              >
                {isSubmitting ? <Loader /> : t('post.save.timezone')}
              </button>
            )}
          </FormActions>
        </FormProvider>
      </div>
      {showVideoUploadModal && (
        <VideoUpload
          onClose={() => setShowVideoUploadModal(false)}
          onSetVideo={handleSetVideo}
          data-testid="PostCreate__videoUploadModal"
        />
      )}
    </Main>
  )
}

export default PostCreate
